<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="出库单ID" >
                <el-input v-model="search.chuKuDanID" size="small" />
            </x-search-item>
            <x-search-item label="出库仓库" >
                <el-input v-model="search.chuKuCangKu" size="small" />
            </x-search-item>
            <x-search-item label="出库类型" >
                <el-input v-model="search.chuKuLX" size="small" />
            </x-search-item>
            <x-search-item label="出库时间" textWidth="138px" hasEnd>
                <el-date-picker v-model="search.chuKuSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.chuKuSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="chuKuDanID" label="出库单ID" />
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
            <el-table-column width="180" prop="caoZuoRenID" label="操作人ID" />
            <el-table-column width="180" prop="caoZuoSJ" label="操作时间" />
            <el-table-column width="180" prop="chuKuCangKu" label="出库仓库" />
            <el-table-column width="180" prop="chuKuLX" label="出库类型" />
            <el-table-column width="180" prop="huoZhi" label="货至" />
            <el-table-column width="180" prop="chuKuShangPinZhongLei" label="出库商品种类" />
            <el-table-column width="180" prop="chuKuZongJinE" label="出库总金额" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" prop="chuKuSJ" label="出库时间" />
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import {query} from "@/service/cggl/ChuKuXX";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/cggl/spckd/ChuKuXXDetail";

    export default {
        name: "ChuKuXXQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = query;
            return {
                search: {
                    chuKuDanID: '',
                    chuKuCangKu: '',
                    chuKuLX: '',
                    chuKuSJBegin: '',
                    chuKuSJEnd: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>